.combined-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label-top {
  margin-bottom: 5px;
}

.label-inline {
  display: flex;
  align-items: center;
}

.label-inline.label-left {
  flex-direction: row; 
  margin-right: 10px;
}

.label-inline.label-right {
  flex-direction: row-reverse;
  margin-left: 0; /* No space between label and input */
}

.field-label {
  font-weight: bold;
  font-family: 'Ethiopic Yebse', sans-serif;
  color: var(--label-text-color);
}

.label-left {
  text-align: left;
}

.label-center {
  text-align: center;
  width: 100%;
}

.label-right {
  text-align: right;
}

.text-select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--select-input-border-color);
  border-radius: 4px;
  padding: 5px;
  background-color: var(--background-color);
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.text-select-wrapper:focus-within {
  outline: none;
  border: 2px solid var(--select-input-border-color);
  box-shadow: 0 0 5px var(--select-input-border-color);
}

.custom-select {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-color);
  flex-grow: 0; /* Ensure the custom select only takes the necessary width */
}

.custom-select .icon {
  margin-right: 4px; /* Reduce space between the icon and the arrow */
}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--background-color);
  border: 1px solid var(--select-input-border-color);
  font-family: 'Ethiopic Yebse', sans-serif;
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.custom-select-options.open {
  max-height: 200px; /* Adjust this value as needed */
  transition: max-height 0.3s ease-in-out;
}

.custom-select-option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  height: 0; /* Initial height */
  opacity: 0; /* Initial opacity */
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.custom-select-option.open {
  height: 40px; /* Adjust to desired height */
  opacity: 1;
}

.custom-select-option:hover {
  background-color: var(--hover-background-color);
}

.option-icon {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
}

.option-label {
  margin-left: 8px;
  color: var(--option-label-color);
}

.icon {
  display: flex;
  align-items: center;
}

.icon img {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
}

.icon .material-icons {
  font-size: 20px; /* Adjust the size as needed */
  color: var(--input-icon-color);
}

.text-field {
  flex-grow: 1;
  border: none;
  padding: 5px;
  color: var(--input-text-color);
  font-size: 16px;
  background-color: var(--background-color);
}

.text-field::placeholder {
  color: var(--input-place-holder-color);
}

.text-field:focus {
  outline: none;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.flex-reverse {
  flex-direction: row-reverse;
}

/* Dropdown arrow */
.dropdown-arrow {
  font-size: 15px;
  color: var(--select-input-border-color); /* Use the CSS variable for color */
  margin-left: 4px; /* Reduce space between arrow and icon */
  pointer-events: none;
}
