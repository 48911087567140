.card-content {
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: var(--card-box-shadow);
  padding: 0px;
  margin: 4% 10% 4% 20%;
  max-width: 60%;
  overflow-y: auto; /* Enable scrolling within the container */ 
  max-height: 80vh; /* Limit the height for scrollable content */ 
  position: relative; /* Required for sticky positioning to work */ 
}

.card-header {
 
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'GO MONO FOR POWERLINE';
  position: sticky;
  top: 0; /* Stick the header to the top of the parent container */
  z-index: 10; /* Ensure the header stays above other content */ 
}


.card-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.tig-title:hover,
.arabic-title:hover,
.english-title:hover {
  border-radius: 0.5em;
  border: 2px solid rgb(255, 255, 255);
  font-weight: bold;
  padding: 0.3em;
}

.tig-title.active,
.arabic-title.active,
.english-title.active {
  border-radius: 0.5em;
  border: 2px solid rgb(255, 255, 255);
  padding: 0.3em;
  font-weight: bold;
}

.horizontal-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.vertical-title {
  display: none;
}

.tig-title {
  font-size: 20px;
  font-family: 'Arial';
}

.arabic-title {
  font-size: 24px;
  font-family: 'Traditional Arabic', 'Arial', sans-serif;
  color: white;
  padding: 5px 10px;
  text-align: center;
}

.english-title {
  font-size: 20px;
  font-family: 'GO MONO FOR POWERLINE';
}

.active {
  font-weight: bold;
  border-bottom: 2px solid white; /* Indicate active title with underline */
}

.verse-block {
  border-bottom: 1px solid #ccc;
}

.verse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em;
  padding-bottom: 0em;
  margin: 0em 0em;
}

.arabic-verse-num {
  font-family: 'Traditional Arabic', 'Arial', sans-serif;
  font-size: 40px;
  color: var(--text-color);
  margin-right: 10%;
  z-index: 1;
}

.verse-number {
  color: var(--text-color);
  padding: 5px 10px;
  margin-left: 1em;
  font-size: 35px;
  z-index: 1;
  font-family: 'GO MONO FOR POWERLINE';
  text-align: right;
}

.arabic-text {
  font-family: 'amiri-bold';
  font-size: 24px;
  text-align: right;
}

.tigrinya-text {
  font-family: 'yebse';
  font-size: 20px;
  display: flex;
}


textarea {
  width: 100%;
  height: 60px;
  margin-top: 5px;
}

button {
  margin-top: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}


.translation-text {
  font-family: 'GO MONO FOR POWERLINE';
  font-size: 16px;
  display: flex;
}

.tigrinya-text,
.translation-text {
  color: var(--text-color);
  text-align: left;
  padding-left: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verse-number-container {
  text-align: right; 
  padding: 0.5em 1em;
}

.btn-back {
  position: fixed;
  top: 15vh;
  right: 2vw;
  padding: 0em;
  text-align: end;
}

.bookmark-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1em;
  color: blue;
  text-decoration: underline;
}

.delete-bookmark-button {
  background: rgb(255, 0, 0);
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.bookmark-card {
  margin-bottom: 20px;
}

.tool-container {
  display: flex; /* Ensure container is flexible */
  align-items: center; /* Center items vertically */
  justify-content: flex-end; /* Align items to the right */
  padding: 0em 1em 0.4em 1em;
  margin-top: -0.5em;
}


.con-txtarea{
  position: absolute;
  width: 55vw;
  height: 50vh;
  background-color: var(--background-color);
  border: 1px solid  #ccc ;
  top: 35vh;
  right: 25vw;
}

.txt-area{
  background-color: var(--background-color);
  width: 50vw;
  height: 10vh;
  align-items: center;

}

/* notes style */
.note-section {
display: inline-block;

}

.note-item {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.save-note{
  margin-right: 1em;

}

.btn-delete{
  padding: 0.1em 0em 0.1em 0.05em;
  margin: 0em -0.7em 0em 0.7em;
}

@media (max-width: 576px) {
  .card-title {
    align-items: center;
    display: block;
  }
  .card-header {
    padding: 0em 0em;
    margin: 0em;
  }

  .vertical-title {
    display: flex !important;
    align-items: center;
  }

  .horizontal-title {
    display: block;
  }

.verse-number {
 margin: 0em ;
 padding: 0em 0.3em;
 
}
  .card-content {
    max-width: 100%;
    margin: 5%;
  }

  .btn-back {
    display: none;
  }

}
