.card {
  border-radius: 0.5em;
  color: var(--text-color);
  overflow: hidden;
  width: 100%;
  max-width: 50em;
  min-width: 10em;
  width: 40em;
  box-shadow: var(--card-box-shadow);
  font-family: 'Ethiopic Yebse-Regular', Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  margin: 1em 5em;
  background-color: var(--body-color);
  transition: box-shadow 0.3s ease;
} 

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.card-header {
  background-color: transparent;
  padding: 0em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -0.2em 0em;
}

.card-content {
  padding: 1em;
  color: var(--text-color);
  flex-grow: 1;
  text-align: justify;
}

.num-title, h2 {
  font-size: 35px;
  padding: 0em 0em;
  margin: 0em 0em;  
}

h2 {
  padding: 0em;
  padding-bottom: 0em;
  margin-top: 0.3em;
}

.arabic-title {
  font-family: 'amiri-quran';
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 2em;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #ff0000;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other content */
}

.close-button:hover {
  color: #ff4040;
}



.card-header.primary {
  background-color: var(--primary-color); 
  color: var(--button-text-color);
  border-color: var(--primary-color);
}

.card-header.secondary {
  background-color: var(--secondary-color);
  color: var(--button-text-color);
  border-color: var(--secondary-color);
}

.card-header.info {
  background-color: var(--info-color);
  color: var(--button-text-color);
  border-color: var(--info-color);
}

.card-header.success {
  background-color: var(--success-color);
  color: var(--button-text-color);
  border-color: var(--success-color);
}

.card-header.warning {
  background-color: var(--warning-color);
  color: var(--button-text-color);
  border-color: var(--warning-color);
}

.card-header.danger {
  background-color: var(--danger-color);
  color: var(--button-text-color);
  border-color: var(--danger-color);
}

/* Responsive Design */
@media (width > 1200px) {
  .card { max-width: 40em; margin: 1.5em auto; }
  .card-header { padding: 0em 1.2em; }
  .card-content { padding: 0.5em; }
  .actions { padding: 1em; }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .card { max-width: 35em; margin: 1.5em auto; }
  .card-header { padding: 0em 1.1em; }
  .card-content { padding: 0.3em; }
  .actions { padding: 1em; }
}

@media (max-width: 991px) and (min-width: 768px) {
  .card { max-width: 30em; margin: 1em; padding: 1em; }
  .card-header { padding: 0em 1em; }
  .card-content { padding: 0.2em; }
  .actions { padding: 1em; }
}

@media (max-width: 767px) and (min-width: 576px) {
  .card { max-width: 100%; margin: 0.1em; padding: 0.5em; }
  .card-header { padding: 0em 1em; }
  .card-content { padding: 0.1em; }
  .actions { padding: 10px; }
  .card-header.info {
    background-color: var(--danger-color);
    color: var(--button-text-color);
    border-color: var(--info-color);
  }
}

@media (max-width: 575px) {
  .card { max-width: 100%; margin: 0.5em; padding: 0.5em; }
  .card-header { padding: 0em 1em; }
  .card-content { padding: 0em; }
  .actions { padding: 0.5em; }
}

@media (max-width: 400px) {
  .card { margin: 0.25em; padding: 0.25em; }
  .card-header { padding: 0em 1em; }
  .card-content { padding: 0.1em; }
  .actions { padding: 1em; }
}
