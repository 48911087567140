/* text-field.module.css */
.input-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Ethiopic Yebse', sans-serif;
  }
  
  .input-container.inline {
    display: flex;
    align-items: center;
  }
  
  .input-label {
    font-weight: bold;
  }
  
  .input-label.top-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-label.inline-label {
    margin-right: 10px;
  }
  
  .input-label.left {
    text-align: left;
  }
  
  .input-label.right {
    text-align: right;
  }
  
  .input-label.center {
    text-align: center;
    width: 100%;
  }
  
  .input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .input-field {
    width: 100%;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
    background-color: var(--background-color);
    color: var(--input-text-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
  }
  
  .input-field:focus {
    border: 2px solid var(--input-border-color);
    box-shadow: 0 0 5px var(--input-border-color);
  }
  
  .input-field::placeholder {
    color: var(--input-placeholder-color);
  }
  
  .input-field.left {
    text-align: left;
  }
  
  .input-field.right {
    text-align: right;
  }
  
  .input-field.center {
    text-align: center;
  }
  
  .input-icon {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: var(--input-icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .children-wrapper {
    margin-top: 10px;
  }
  
  .validation-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  