.card {
  max-width: 20em;
  padding: 0em;
  margin: 0em 5em;
  align-self: center;
}

.card-content {
  background-color: var(--background-color);
  border-radius: 10px;
  color: var(--text-color);
  padding: 0em 1em;
  margin: 0em 1em;
}

.surah-container {
  justify-content: space-between;
  display: flex;
}



.tigrigna-title,
.tigrigna-text {
  font-size: 20px;
  font-family: 'yebse';
  font-weight: 400;
}

.english-title,
.english-text {
  font-size: 18px;
  font-family: 'GO MONO BOLD FOR POWERLINE';
}

.english-text,
.tigrigna-text {
  font-weight: 400;
  color: #898989;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2em;
  justify-content: center;
  padding: 3em 10em;
}

@media (max-width: 576px) {
  .card {
      max-width: 20em;
      padding: 0em;
      margin: 0.8em 1em;
  }

  .card-content {
      padding: 0.5em 1em;
  }

  .container {
      justify-content: center;
      gap: 0em;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
