/* Footer Styles */
.footer {
  background-color: var(--footer-bg-color, #1e1e1e);
  color: var(--footer-text-color, #f4f4f4);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
}

/* Container */
.footerContainer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  gap: 3em;
  flex-wrap: wrap;
}

/* Quick Links Section */
.linksSection {
  flex: 1;
  color: #b3b3b3;
  margin: 1em 0;
  text-align: left;
}

.linksSection h2 {
  font-size: 1.3em;
  margin-bottom: 0.7em;
  color: #fff;
  border-bottom: 2px solid #4c9aff;
  display: inline-block;
  padding-bottom: 0.3em;
}

.linksSection ul {
  list-style: none;
  padding: 0;
}

.linksSection li {
  margin-bottom: 0.6em;
}

.linksSection a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.linksSection a:hover {
  color: #4c9aff;
}

/* Contact Section */
.contactSection {
  flex: 1;
  color: #b3b3b3;
  margin: 1em 0;
  text-align: left;
}

.contactSection h2 {
  font-size: 1.3em;
  margin-bottom: 0.7em;
  color: #fff;
  border-bottom: 2px solid #4c9aff;
  display: inline-block;
  padding-bottom: 0.3em;
}

.contactSection textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.7em;
  border: 1px solid #666;
  border-radius: 5px;
  margin-bottom: 0.5em;
  resize: none;
  background: #292929;
  color: #ddd;
}

.contactSection textarea::placeholder {
  color: #aaa;
}

.contactSection button {
  padding: 0.7em 1.5em;
  background-color: #007bff;
  color: #f4f4f4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.contactSection button:hover {
  background-color: #0056b3;
}

.contactSection button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Download App Button */
.downloadSection {
  flex: 1;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;

}

.installButton {
  padding: 0.7em 1.5em;
  background-color: #28a745;
  color: #f4f4f4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
 font-size: 100%;
 width: 60%;
 animation: zoomInOut 2s infinite ease-in-out; /* Apply animation */
}

.installButton:hover {
  background-color: #2dbb4b;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow effect */
}
@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Zoomed-in size */
  }
}

.downloadIcon {
  background-color: #28a745; /* Green background */
  padding: 0.4em 0em; /* Padding around the icon */
  border-radius: 50%; /* Circular background */
 
}

/* Footer Bottom */
.footerBottom {
  margin-top: 1.5em;
  color: #aaa;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  padding-top: 1em;
  border-top: 1px solid #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footerContainer {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }
  .linksSection, .contactSection, .downloadSection {
      width: 80%;
      text-align: center;
  }
  .installButton{
    width: 90%;
    padding: 0.5em 1.5em;
  }

}

@media (max-width: 576px) {
  .footer {
      padding: 1em 0;
  }
  .installButton{
    width: 65%;
    padding: 0.5em 1.5em;
  }
}
