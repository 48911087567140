.select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Ethiopic Yebse', sans-serif;
  width: 100%;
}

.select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.select-field {
  flex-grow: 1;
  padding: 10px 40px 10px 10px; /* Adjusted padding to make room for the icon on the left and dropdown arrow on the right */
  font-size: 16px;
  background-color: var(--background-color);
  color: var(--select-input-place-holder-color);
  border: 1px solid var(--select-input-border-color);
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  text-align: left;
  height: 42px; /* Ensure consistent height */
  line-height: 1.5;
}

.select-field:focus {
  outline: none;
  border: 2px solid var(--select-input-border-color);
  box-shadow: 0 0 5px var(--select-input-border-color);
}

.select-field.selected-option {
  color: var(--selected-option-color); /* Selected option color */
}

.select-container::after {
  content: '▼';
  font-size: 15px;
  color: var(--select-input-icon-color);
  position: absolute;
  top: 60%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-container.icon::before {
  content: attr(data-icon);
  font-family: 'Material Icons';
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--select-input-icon-color);
}

.label-top {
  display: block;
  margin-bottom: 8px;
}

.label-inline {
  display: flex;
  align-items: center;
}

.label-align-left {
  margin-right: 8px; /* Add margin to the right when label is left-aligned */
}

.label-align-center {
  text-align: center;
}

.label-align-right {
  margin-left: 8px; /* Add margin to the left when label is right-aligned */
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

/* Additional CSS for Placeholder Alignment */
.placeholder-align-left::placeholder {
  text-align: left;
}

.placeholder-align-center::placeholder {
  text-align: center;
}

.placeholder-align-right::placeholder {
  text-align: right;
}
